<template>
	<div style="margin-top:20px;" v-if="user_pss=='Soruda888'">
		<!-- 配方列表 -->
		<div v-if="pro_pagetype==1">
	<div style="width: 82%;margin-left:2%;float: left;margin-top:-6px;">
	<el-input placeholder="请输入配方名称" v-model="inputs" class="input-with-select">
	<el-select v-model="selects" slot="prepend" placeholder="请选择" style="width:118px;z-index:10000;">
		  <el-option :label="item.pro_name" :value="item.id" v-for="(item,index) in typeList_Data" :key="index"></el-option>
		  <!-- <el-option label="微潮色" value="2"></el-option>
		  <el-option label="潮色" value="3"></el-option>
		  <el-option label="深染浅" value="4"></el-option>
		  <el-option label="浅染深" value="5"></el-option>
		  <el-option label="冷棕色" value="6"></el-option>
		  <el-option label="暖棕色" value="7"></el-option>
		  <el-option label="多段色" value="8"></el-option>
		  <el-option label="一步染" value="9"></el-option>
		  <el-option label="盖白发" value="10"></el-option>
		  <el-option label="人工色" value="11"></el-option>
		  <el-option label="画染" value="12"></el-option>
		  <el-option label="欧系" value="13"></el-option>
		  <el-option label="日系" value="14"></el-option> -->
	</el-select>
			<el-button slot="append" icon="el-icon-search" @click="get_pflist"></el-button>
		</el-input>
	</div>
		<div @click="pf_add(2)" class="sbgl_botton">
			<img class="sbgl_botton_img" src="../../public/icon/addsb.png" /> 添加配方
		</div>
		  <el-table
			  ref="multipleTable"
			  :data="pro_list"
			  tooltip-effect="dark"
			  style="width:99%;height: 100%;margin-left: 1%;"
			  @selection-change="handleSelectionChange">
			 <!-- <el-table-column
				type="selection"
				width="56">
			  </el-table-column> -->
			  <el-table-column
				label="色板类型"
				width="138">
				<template slot-scope="scope">{{ scope.row.pid }}</template>
			  </el-table-column>
			  <el-table-column
				prop="name"
				label="分类名称"
				width="138">
			  <template slot-scope="scope">{{ scope.row.types }}</template>
			  </el-table-column>
			  <el-table-column
				prop="name"
				label="配方名称"
				width="138">
			  <template slot-scope="scope">{{ scope.row.name }}</template>
			  </el-table-column>
			  <el-table-column
				prop="name"
				label="程序编码"
				width="100">
			  <template slot-scope="scope">{{ scope.row.codes }}</template>
			  </el-table-column>
			  <el-table-column
				prop="address"
				label="配方图片"
			  width="128"
				show-overflow-tooltip>
				<template slot-scope="scope">
				  <el-image v-if="scope.row.imgs" :src="scope.row.imgs" style="width:68px;height:68px;border-radius:20rpx;"></el-image>
<!-- 				  <image v-else style="width:68px;height:68px;border-radius:20rpx;background-color: #f8f8f8;"></image> -->
				</template>
			  </el-table-column>
			<el-table-column
			  prop="address"
			  label="添加时间"
			  width="138"
			  show-overflow-tooltip>
			  <template slot-scope="scope">{{ scope.row.addtime }}</template>
			</el-table-column>
			
			<el-table-column
				  fixed="right"
				  label="操作"
				  width="168">
				  <template slot-scope="scope">
					  <el-button size="mini" @click="handleEdit(scope.row.id, scope.row)">编辑</el-button>
					<el-button size="mini" type="danger" @click="showConfirm(scope.row.id, scope.row)">删除</el-button>
				  </template>
				</el-table-column>
			</el-table>
			<div style="width:100%;text-align: center;">
					<el-row>
					  <el-pagination
					    background
						@size-change="handleSizeChange"   
						@current-change="handleCurrentChange"
						:current-page="pages" 
						:page-size="pageSize"
					    layout="total, sizes, prev, pager, next, jumper"
					    :total="page_count">
					  </el-pagination>
					</el-row>
			</div>
			<div style="width:100%;height:10px;float: left;"></div>
		</div>
		
		<!-- 添加配方 -->
		<div v-if="pro_pagetype==2" style="width: 98%;margin-left:0.5%;">
				<el-form ref="form" :model="form" label-width="80px">
				  <el-form-item label="配方名称">
				    <el-input v-model="form.name"></el-input>
				  </el-form-item>
				  <el-form-item label="程序编码">
				    <el-input v-model="form.codes"></el-input>
				  </el-form-item>
				  <el-form-item label="配方克数">
				    <el-input v-model="form.nums"></el-input>
				  </el-form-item>
				  <el-form-item label="色系类型">
					<el-select v-model="form.pid" placeholder="请选择色系类型">
				      <el-option :label="item.pro_name" :value="item.id" v-for="(item,index) in typeList_Data" :key="index"></el-option>
				      <!-- <el-option label="微潮色" value="2"></el-option>
					  <el-option label="潮色" value="3"></el-option>
					  <el-option label="深染浅" value="4"></el-option>
					  <el-option label="浅染深" value="5"></el-option>
					  <el-option label="冷棕色" value="6"></el-option>
					  <el-option label="暖棕色" value="7"></el-option>
					  <el-option label="多段色" value="8"></el-option>
					  <el-option label="一步染" value="9"></el-option>
					  <el-option label="盖白发" value="10"></el-option>
					  <el-option label="人工色" value="11"></el-option>
					  <el-option label="画染" value="12"></el-option>
					  <el-option label="欧系" value="13"></el-option>
					  <el-option label="日系" value="14"></el-option> -->
				    </el-select>
					<!--<el-radio-group v-model="radio1" size="mini">
						<el-radio-button label="1">生活色</el-radio-button>
						<el-radio-button label="2">微潮色</el-radio-button>
						<el-radio-button label="3">潮色</el-radio-button>
						<el-radio-button label="4">深染浅</el-radio-button>
						<el-radio-button label="5">浅染深</el-radio-button>
						<el-radio-button label="6">冷棕色</el-radio-button>
						<el-radio-button label="7">暖棕色</el-radio-button>
						<el-radio-button label="8">多段色</el-radio-button>
						<el-radio-button label="9">一步染</el-radio-button>
						<el-radio-button label="10">盖白发</el-radio-button>
						<el-radio-button label="11">人工色</el-radio-button>
						<el-radio-button label="12">画染</el-radio-button>
						<el-radio-button label="13">欧系</el-radio-button>
						<el-radio-button label="14">日系</el-radio-button>
					</el-radio-group> -->
				  </el-form-item>
				<!--  <el-form-item label="启用时间">
				    <el-col :span="11">
				      <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
				    </el-col>
				    <el-col class="line" style="color: #fff;" :span="1">-</el-col>
				    <el-col :span="11">
				      <el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;"></el-time-picker>
				    </el-col>
				  </el-form-item> -->
				  <!-- <el-form-item label="是否显示">
				    <el-switch v-model="form.delivery" active-value="1"
      inactive-value="0"></el-switch>
				  </el-form-item> -->
				  
				  <!-- <el-form-item label="分类名称">
				    <el-checkbox-group v-model="form.type">
				      <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
				      <el-checkbox label="地推活动" name="type"></el-checkbox>
				      <el-checkbox label="线下主题活动" name="type"></el-checkbox>
				      <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
				    </el-checkbox-group>
				  </el-form-item> -->
				  <el-form-item label="分类名称">
					  <el-radio-group v-model="form.types" size="small">
						<el-radio-button label="1">灰色</el-radio-button>
						<el-radio-button label="2">绿色</el-radio-button>
						<el-radio-button label="3">黄色</el-radio-button>
						<el-radio-button label="4">橙色</el-radio-button>
						<el-radio-button label="5">深红</el-radio-button>
						<el-radio-button label="6">紫色</el-radio-button>
						<el-radio-button label="7">深棕</el-radio-button>
						<el-radio-button label="8">浅棕</el-radio-button>
						<el-radio-button label="9">冷棕</el-radio-button>
						<el-radio-button label="10">暖棕</el-radio-button>
						<el-radio-button label="11">蓝色</el-radio-button>
						<el-radio-button label="12">基色</el-radio-button>
						<el-radio-button label="13">褪色</el-radio-button>
					  </el-radio-group>
				
				   <!-- <el-radio-group v-model="form.resource" style="line-height: 36px;">
						<el-radio v-model="radio1" label="灰色" border>灰色</el-radio>
						<el-radio v-model="radio1" label="绿色" border>绿色</el-radio>
						<el-radio v-model="radio1" label="黄色" border>黄色</el-radio>
						<el-radio v-model="radio1" label="橙色" border>橙色</el-radio>
						<el-radio v-model="radio1" label="深红" border>深红</el-radio>
						<el-radio v-model="radio1" label="紫色" border>紫色</el-radio>
						<el-radio v-model="radio1" label="深棕" border>深棕</el-radio>
						<el-radio v-model="radio1" label="浅棕" border>浅棕</el-radio>
						<el-radio v-model="radio1" label="冷棕" border>冷棕</el-radio>
						<el-radio v-model="radio1" label="暖棕" border>暖棕</el-radio>
						<el-radio v-model="radio1" label="蓝色" border>蓝色</el-radio>
						<el-radio v-model="radio1" label="基色" border>基色</el-radio>
						<el-radio v-model="radio1" label="褪色" border>褪色</el-radio>
				      <el-radio label="灰色"></el-radio>
				      <el-radio label="绿色"></el-radio>
					  <el-radio label="黄色"></el-radio>
					  <el-radio label="橙色"></el-radio>
					  <el-radio label="深红色"></el-radio>
					  <el-radio label="紫色"></el-radio>
					  <el-radio label="深棕"></el-radio>
					  <el-radio label="浅棕"></el-radio>
					  <el-radio label="冷棕"></el-radio>
					  <el-radio label="暖棕"></el-radio>
					  <el-radio label="蓝色"></el-radio>
					  <el-radio label="基色"></el-radio>
					  <el-radio label="褪色"></el-radio>
				    </el-radio-group> -->
				  </el-form-item>
				  <el-form-item label="上传图标" style="margin-top: 66px;">
				  	 <el-upload
				  	  style="margin-top: -50px;"
				  	  list-type="picture-card"
				  	  action="''"
					  :accept="'.img,.png,.jpg,.jpeg'"
				  	  :http-request="upload"
				  	  :before-upload="beforeAvatarUpload">
				  	  <i class="el-icon-plus" ></i>
				  	 </el-upload>
<!-- <img v-else :src="image_urls" style="width:150px;height:150px;"/> -->
				  </el-form-item>
				  <el-form-item label="图标">
				    <el-input type="textarea" v-model="image_url" placeholder="图片路径及名称"></el-input>
				  </el-form-item>
				  <el-form-item label="灰色0.1">
				    <el-input v-model="form.colora"></el-input>
				  </el-form-item>
				  <el-form-item label="绿色G9.77">
				    <el-input v-model="form.colorb"></el-input>
				  </el-form-item>
				  <el-form-item label="黄色Y9.33">
				    <el-input v-model="form.colorc"></el-input>
				  </el-form-item>
				  <el-form-item label="橙色6.64">
				    <el-input v-model="form.colord"></el-input>
				  </el-form-item>
				  <el-form-item label="红色0.2">
				    <el-input v-model="form.colore"></el-input>
				  </el-form-item>
				  <el-form-item label="紫色0.2">
				    <el-input v-model="form.colorf"></el-input>
				  </el-form-item>
				  <el-form-item label="棕色5.52">
				    <el-input v-model="form.colorg"></el-input>
				  </el-form-item>
				  <el-form-item label="蓝色B8.11">
				    <el-input v-model="form.colorh"></el-input>
				  </el-form-item>
				  <el-form-item label="自然黑">
				    <el-input v-model="form.colori"></el-input>
				  </el-form-item>
				  <el-form-item label="褪色">
				    <el-input v-model="form.colorj"></el-input>
				  </el-form-item>
				  <el-form-item label="极浅灰金">
				    <el-input v-model="form.colork"></el-input>
				  </el-form-item>
				  <el-form-item label="梦幻紫">
				    <el-input v-model="form.colorl"></el-input>
				  </el-form-item>
				  <el-form-item label="浅棕色">
				    <el-input v-model="form.colorm"></el-input>
				  </el-form-item>
				  <el-form-item label="深灰亚麻">
				    <el-input v-model="form.colorn"></el-input>
				  </el-form-item>
				  <el-form-item label="双氧乳">
				    <el-input v-model="form.coloro" placeholder="10VOL"></el-input>
				  </el-form-item>
				  <el-form-item label="双氧乳">
				    <el-input v-model="form.colorp" placeholder="20VOL2:1"></el-input>
				  </el-form-item>
				  <el-form-item label="双氧乳">
				    <el-input v-model="form.colorq" placeholder="30VOL1:2"></el-input>
				  </el-form-item>
				  <el-form-item label="双氧乳">	
				    <el-input v-model="form.colorr"  placeholder="40VOL"></el-input>
				  </el-form-item>
				  <el-form-item label="排序">
				    <el-input v-model="form.sort"  placeholder="数值越大排序越前"></el-input>
				  </el-form-item>
				  <el-form-item label="使用说明">
				    <el-input type="textarea" v-model="form.Instructions" placeholder="描述配方的使用说明"></el-input>
				  </el-form-item>
				  <el-form-item>
				    <el-button type="primary" @click="pf_submit">添加配方</el-button>
				    <el-button @click="pf_add(1)" >取消</el-button>
				  </el-form-item>
				</el-form>
				<div style="width:100%;height:16px;float: left;"></div>
		</div>
		
	</div>
</template>

<script>
  // import { Switch } from 'element-ui';
  // import axios from 'axios';
  export default {
    data() {
      return {
		pages:1,
		page_count:0,
	    pageNo:1,      // 默认当前是第一页
	    pageSize:20,    // 当前每页的数据是5条
	    totalCount:0   ,// 总数默认为0
		  
		handleSelectionChange:'',
		pro_list:[],
		pro_pagetype:1,
		inputs: '',
		selects: '',
		
		options: [{
		  value: '选项1',
		  label: '黄金糕'
		}, {
		  value: '选项2',
		  label: '双皮奶'
		}, {
		  value: '选项3',
		  label: '蚵仔煎'
		}, {
		  value: '选项4',
		  label: '龙须面'
		}, {
		  value: '选项5',
		  label: '北京烤鸭'
		}],
		value: '',
		radio1: '',
		radio2: '',
		image_url:'',
		form: {},
		typeList_Data:'',
		user_pss:'',
      }
    },
	mounted: function(){
		this.user_pss = localStorage.getItem('user_pss') || '';
		if(!this.user_pss || this.user_pss==''){
			// this.$router.push('/windows'); 
			return false;
		}
		
		this.get_pflist();//需要触发的函数
		this.get_type();
	},
    methods: {
		//获取配方类型
		get_type(){
			var that=this;
			let url = this.URL_HOST + "/index/admin/get_aisrd_type";
			this.$axios({
				method: 'post',
				url: url,
				data: {
					openid:"",
				},
			}).then(res=>{
				console.log(res);
				that.typeList_Data=res.data.data;
				
			},err=>{
				console.log(err);
			})
		},
		// 图片上传前验证
		beforeAvatarUpload (file) {
			const isLt2M = file.size / 1024 / 1024 < 2
			if (!isLt2M) {
			 this.$message.error('上传头像图片大小不能超过 2MB!')
			}
			return isLt2M;
		},
		upload (params) {
		  var that=this;
		  const formData = new FormData();
		  formData.append('file', params.file);
		  // console.log("formData:",formData);
		  let url = this.URL_HOST + "/agent/upload/img_save_aipro";
		  this.$axios({
		  	method: 'post',
		  	url: url,
			headers: {
			  'Content-Type': 'multipart/form-data',
			},
		  	data: formData
		  }).then(res=>{
		  	this.$message({ type: 'success', message: '上传成功' })
		  	if(res.data.code==1){
		  		that.image_url=res.data.url;
		  	}else{
		  		that.$message({ type: 'error', message: '图片上传失败！' })
		  	}
		  },err=>{
				that.$message({ type: 'error', message: '上传失败' })
		  });
		},
		handleSizeChange(val) { // 修改每页所存数据量的值所触发的函数
		    this.pageSize = val;  // 修改页的大小
		     this.get_pflist();       // 按新的pageNo和pageSize进行查询
		},
		handleCurrentChange(val) { // 修改当前页所触发的函数
		  this.pages = val;       // 更新当前的页
		  this.get_pflist();          // 按新的pageNo和pageSize进行查询
		},
		get_pflist(){
			var that=this;
			let url = this.URL_HOST + "/index/Aisrd/pf_list";
			this.$axios({
				method: 'post',
				url: url,
				data: {
					pages:that.pages,
					pageSize:that.pageSize,
					inputs: that.inputs,
					selects: that.selects,
				}
			}).then(res=>{
				that.pro_list=res.data.data;
				that.page_count=res.data.page_count;
				if(res.data.code==200){
					for(var i=0;i < res.data.data.length ; i++){
						that.pro_list[i].imgs=that.URL_HOST + res.data.data[i].imgs;
						console.log(that.pro_list[i].imgs);
					}
				}
				
				
			},err=>{
				console.log(err);
			})
		},
		pf_submit(){
			var that=this;
			if(that.form.name){
				let url = this.URL_HOST + "/index/Aisrd/pf_add";
				this.$axios({
					method: 'post',
					url: url,
					data: {
						form:that.form,
						image_url:that.image_url,	
					}
				}).then(res=>{
					if(res.data.code==200){
						that.$message.success("配方添加成功！");					 		that.form="{}";
						that.pro_pagetype=1;
						that.get_pflist();
					}else{
						that.$message.error("配方添加错误！");	
					}
				},err=>{
					that.$message.error("配方添加错误！");	
				})
			}else{
				that.$message.error("添加失败,配方名称不能为空！");	
			}
		},
		pf_add(e){
			if(e==1){
				// this.$router.go(0); // 重载当前页面
				this.$router.go(-1); // 重载当前页面
				this.$router.replace({ path: '/pfsz' }); // 通过指定相同的路由路径进行刷新
			}
			this.pro_pagetype=e;
		},
		
		handleEdit(index, row){
			this.$router.push('/pfsz_add?id=' + index);
			localStorage.setItem('pageid', index);
			// this.$router.replace({ path: '/pfsz_add' }); 
			// console.log(index, row);
		},
		  showConfirm(index, row) {
		        this.$confirm('您确定要删除此配方吗?', {
		        confirmButtonText: '确定',
		        cancelButtonText: '取消',
		        type: 'warning'
		        }).then(() =>{
		        this.handleDelete(index, row);
		        }).catch(() =>{});
		  },
		  handleDelete(index,row) {
			var that=this;
			let url = this.URL_HOST + "/index/Aisrd/pf_del";
			this.$axios({
				method: 'post',
				url: url,
				data: {
					id:index
				}
			}).then(res=>{
				if(res.data.code==200){
					that.$message.success("删除成功！");
					that.get_pflist();
					// this.$router.go(0);
					// location.reload();
				}
			},err=>{
				console.log(err);
			})  
		  }
    }
  }
</script>

<style>
		page{
			width:100%;height:100%;
			background:linear-gradient(to bottom,#197bcf,#3288fc);
		}
		.add_windows_bg{
			width:100%;height:100%;position:fixed;z-index:100;background-color: #000;opacity: 0.88;
		}
		.add_windows{
			width:61%;height:61%;border-radius:5px;position:fixed;z-index:101;
			margin: auto;
			left: 0; /*实现div的居中*/
			right: 0; /*实现div的居中*/
			top:0;
			bottom:0;
		}
		.windows_title{
			width:100%;height:100%;
			position: fixed;
			/* 支持窗口拖拽功能 */
			-webkit-app-region:drag;
		}
		.win_button{width:150px;height:26px;float: right;-webkit-app-region:no-drag;}
		.win_button_img{width:15px;height:15px;padding: 5px;margin-right:8px;float: right;border-radius: 1px;-webkit-app-region:no-drag;}
		.win_button_img:hover{
			background:rgba(255,255,255,0.2);
		}
		.win_button_img_close:hover{
			background:red;
		}
		.body{
			width: 96%;
			margin: 0 auto;
			left:50%;
			top:50%;
			transform: translate(-50%,-50%);
			position: absolute;
			-webkit-app-region:no-drag;
		}
		.logo{
			width:38%;height:100%;float:left;
			-webkit-app-region:drag;
		}
		.title{
			width:100%;height:12%;border-radius:20px;
		}
		.left_select{
			background:rgba(255,255,255,0.2);
		}
		.left_click{
			background:rgba(255,255,255,0.2);
		}
		
	::-webkit-scrollbar{
		width:10px;
		height:12px;
		border-radius: 20px;;
		background-color: #eaeef8;
	  }
	  ::-webkit-scrollbar-thumb{
		border-radius: 20px;;
		background-color: #bfcbe2;
	  }	

.sbgl_botton{width:82px;margin-bottom:5px;margin-top:-3px; text-align: center;background-color: #409eff;min-width:82px;height:36px;border-radius:5px;text-align: center;line-height: 36px;font-size: 13px;padding: 0px 6px 0px 6px;color:#fff;float: right;margin-right: 10px;}
.sbgl_botton_img{width:22px;height:22px;margin-bottom:-6px;}
.sbgl_botton:hover{opacity: 0.8;cursor: pointer; /* 将光标样式设置为手型 */}

 .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
.box-mode{
	margin-top: -76px;
}
.avatar-uploader {
	margin-top: 20px;
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	width: 138px;
	height: 138px;
}
.avatar-uploader:hover {
	border-color: #409EFF;
}
</style>